import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { Link } from '@reach/router'
import styled, { css } from 'styled-components'
import pickColor from '../utlis/pickColor'
import Paragraph from './paragraph'
import Img from 'gatsby-image'

const ExtendedLink = styled(Link)`
  width: calc(50% - 20px);
  text-decoration: none;
  color: black;
  transform: scale(1);
  transition: all 0.4s ease;
  &:hover {
    opacity: 0.9;
    transform: scale(1.03);
    transition: all 0.3s ease;
  }
  @media screen and (max-width: 560px) {
    width: 100%;
  }
`
const Image = styled(Img)`
  width: 100%;
  margin-bottom: 10px;
  height: 271px;

  @media screen and (max-width: 560px) {
    margin-bottom: 20px;
    height: auto;
  }
`

const Title = styled.h2`
  font-family: ${props => (props.title ? 'Larsseit-Bold' : 'Grotesk-SemiBold')};
  font-size: 27px;
  line-height: 35px;
  padding-bottom: ${props => (props.offset ? props.offset : '30px')};
  margin-top: ${props => props.marginTop && props.marginTop};
  color: ${props => pickColor(props.color)};
  @media screen and (max-width: 768px) {
    font-size: 21px;
    line-height: 29px;
  }
  ${props =>
    props.subtitle &&
    css`
      font-family: 'Larsseit-Bold';
    `}
`

const LISTING_QUERY = graphql`
  query BlogPostArchive {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          excerpt
          frontmatter {
            title
            slug
            image {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    }
  }
`

const BlogList = ({ locale }) => {
  return (
    <StaticQuery
      query={LISTING_QUERY}
      render={({ allMarkdownRemark }) => {
        if (locale === 'en') {
          return allMarkdownRemark.edges.filter(edge => edge.node.frontmatter.slug.endsWith('-en')).map(edge => {
            return <ExtendedLink
              title={edge.node.frontmatter.title}
              to={`novosti-iz-turizma-i-vodici-za-iznajmljivace${edge.node.frontmatter.slug}`}
            >
              <article key={edge.node.frontmatter.slug}>
                <Image
                  alt={edge.node.frontmatter.title}
                  title={edge.node.frontmatter.title}
                  fluid={edge.node.frontmatter.image.childImageSharp.fluid}
                />
                <Title title offset="5px" medium>
                  {edge.node.frontmatter.title}
                </Title>
                <Paragraph offset="40px">{edge.node.excerpt}</Paragraph>
              </article>
            </ExtendedLink>
          }

          )
        }
        return allMarkdownRemark.edges.map(edge => {
          return <ExtendedLink
            title={edge.node.frontmatter.title}
            to={`novosti-iz-turizma-i-vodici-za-iznajmljivace${edge.node.frontmatter.slug}`}
          >
            <article key={edge.node.frontmatter.slug}>
              <Image
                alt={edge.node.frontmatter.title}
                title={edge.node.frontmatter.title}
                fluid={edge.node.frontmatter.image.childImageSharp.fluid}
              />
              <Title title offset="5px" medium>
                {edge.node.frontmatter.title}
              </Title>
              <Paragraph offset="40px">{edge.node.excerpt}</Paragraph>
            </article>
          </ExtendedLink>
        }

        )
      }
      }
    />
  )
}

export default BlogList
